.front-page {
  min-height: 100vh;
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: auto;
  .content {
    max-width: 1920px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    width: 1200px;
    margin: auto;
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem 1.8rem;
    margin: 0 0 0 3rem;
  }
  .main-section {
    max-width: 1200px;
    max-height: 800px;
    margin: auto;
    display: flex;
    flex-grow: 1;
    .left-section {
      flex-grow: 1;
      width: 15%;
      padding: 1rem;
      font-size: 1.2rem;
      .list-items-left {
        margin: 0;
        list-style: none;
        padding: 0;
        .ca {
          font-size: 0.7rem;
        }
        .list-item-left {
          padding: 0.7rem;
          text-decoration: underline;
          font-weight: 700;
          a {
          }
        }
      }
      img {
        height: 350px;
        // padding-left: 50px;
      }
    }
    .right-section {
      width: 80%;
      text-align: justify;
      .list-items {
        list-style: none;
        font-size: 1rem;
        font-weight: 500;
        .list-item {
          padding: 0.5rem;
        }
      }
      .bold {
        font-weight: 700;
      }
    }
  }
  .disclaimer {
    max-width: 1200px;
    margin: auto;
    font-size: 0.8rem;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-img {
    display: none;
  }
}

@media screen and (min-width: 1921px) {
  .front-page {
    display: flex;
    justify-content: center;
    // max-height: 800px;
    .main-section {
      flex-grow: 0;
      margin: 0 auto;
    }
  }
}
// @media screen and (max-width: 1200px) {
//   .front-page {
//     .left-section {
//       .list-item-left {
//         padding: 0;
//       }
//     }
//   }
// }
@media screen and (max-width: 1200px) {
  .front-page {
    height: 100%;
    .content {
      width: auto;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .title {
      margin: 0;
      padding: 2rem 0 0 0;
      text-align: center;
      width: auto;
    }
    .main-section {
      display: flex;
      flex-direction: column;
      max-height: 2000px;
      .left-section {
        font-size: 1rem;
        width: 100%;
        padding-left: 0;
        .list-item-left {
          padding: 0;
          margin: 0;
        }
        .ca {
          display: none;
        }
        .list-items-left {
          display: flex;
          justify-content: center;
          padding: 0;
          margin: 0;
        }
        img {
          display: none;
        }
      }
      .right-section {
        margin: auto;
        text-align: justify;
        // padding-bottom: 2rem;
        .list-items {
          padding: 0;
          font-size: 1rem;
        }
      }
    }
    .mobile-img {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      img {
        height: 200px;
      }
    }
    .disclaimer {
      font-size: 0.5rem;
      text-align: justify;
    }
  }
}
