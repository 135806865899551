.App {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: black;
  color: #09ff00;
  overflow: hidden;
  a {
    color: #09ff00;
  }
}
